/* eslint-disable vue/one-component-per-file */
import { trackEvent } from "modules/analytics";
import { cobrandLink } from "virtuoso-shared-web-ui";
import { createApp } from "vue";
import { mountApp } from "vue-app";
import DynamicPage from "vue-components/dynamic-page.vue";
import InspirationLandingPage from "vue-components/pages/inspiration-landing-page.vue";
import TopicFrontComponent from "vue-components/pages/topic-front.vue";


if (window.VIRTUOSO.topicKey && window.VIRTUOSO.topicKey !== "index") {
    if (window.VIRTUOSO.topicKey === "virtuoso-life") {
        // Immediate, redirect to virtuoso the magazine topic front instead
        location.href = cobrandLink("/travel/virtuoso-the-magazine");
    }

    if (window.VIRTUOSO.topicKey === "sustainable-travel") {
        const app = createApp(DynamicPage,
            {
                cmsPageEndpointPath: `/inspiration/${window.VIRTUOSO.topicKey}`
            }
        );
        mountApp(app, "page-app");
    } else {
        const app = createApp(TopicFrontComponent,
            {
                topicKey: window.VIRTUOSO.topicKey,
                destinationKey: window.VIRTUOSO.destinationKey
            });
        mountApp(app, "page-app");
    }

} else { // index -- inspiration landing page
    mountApp(createApp(InspirationLandingPage), "page-app");
    trackEvent("entry_view", { item_name: "Inspiration_Home", item_category: "Inspiration" });
}

<template>
    <div v-if="isReady">
        <h1 class="container px-lg-0 my-2">Inspiration</h1>

        <section>
            <div class="container px-lg-0">
                <feature-cards class="destination-catalogs" :content="contentCards"></feature-cards>
            </div>
        </section>

        <find-an-advisor-cta></find-an-advisor-cta>

        <div class="ad-zone">
            <broadstreet-zone zone-id="78463"></broadstreet-zone>
        </div>

        <product-cards-component :content="productCardsHotels" class="product-cards-container"></product-cards-component>
        <product-cards-component :content="productCardsCruises" :class="['product-cards-container', { 'pt-0': productCardsHotels.productCards.length }]"></product-cards-component>
        <product-cards-component :content="productCardsTours" :class="['product-cards-container', { 'pt-0': (productCardsHotels.productCards.length || productCardsCruises.productCards.length ) }]"></product-cards-component>
    </div>
    <LogoSplash v-else />
</template>


<script setup lang="ts">
    import { getCmsContent } from "api/cms";
    import { FeatureCard, ProductCards } from "interfaces/card";
    import { ProductType } from "interfaces/enums";
    import { DotCMSHtmlPageAsset } from "interfaces/html";
    import { baseImageUrl } from "modules/cms";
    import { getProductCards, getViewAllLabel } from "modules/product-cards";
    import { toastError } from "modules/toasts";
    import { cobrandLink } from "virtuoso-shared-web-ui";
    import { Ref, ref } from "vue";
    import FeatureCards from "vue-components/feature-cards.vue";
    import FindAnAdvisorCta from "vue-components/find-an-advisor-cta.vue";
    import LogoSplash from "vue-components/logo-splash.vue";
    import ProductCardsComponent from "vue-components/product-cards.vue";

    const isReady = ref(false);
    // Show inspiration links in a grid
    const contentCards: Ref<FeatureCard[]> = ref<FeatureCard[]>([]);

    const productCardsCruises: Ref<ProductCards> = ref<ProductCards>({
        header: "Cruises",
        productCards: [],
        productType: ProductType.CRUISES,
        viewAllLink: cobrandLink("/travel/luxury-cruises"),
        viewAllText: "See More Cruises"
    });

    const productCardsHotels: Ref<ProductCards> = ref<ProductCards>({
        header: "Hotels",
        productCards: [],
        productType: ProductType.HOTELS,
        viewAllLink: cobrandLink("/travel/luxury-hotels/search"),
        viewAllText: "See More Hotels"
    });

    const productCardsTours: Ref<ProductCards> = ref<ProductCards>({
        header: "Tours & Experiences",
        productCards: [],
        productType: ProductType.TOURS,
        viewAllLink: cobrandLink("/travel/luxury-tours/search"),
        viewAllText: "See More Tours & Experiences"
    });


    // Get the pages in the /inspiration/ directory (minus specific ones)
    getCmsContent<DotCMSHtmlPageAsset[]>({
        contentTypes: ["htmlpageasset"],
        limit: 20,
        queryClauses: [
            "+(conFolder:5fd9f7b5-c6bf-410d-9215-bb3a897bccd1)",
            "+(htmlpageasset.showOnMenu:true)"
        ],
        sort: "title"
    }).then((pagesJSON) => {
        if (pagesJSON?.length) {

            // Transform into a simple shape
            pagesJSON.forEach((page) => {
                contentCards.value.push({
                    imageUrl: baseImageUrl + page.image,
                    name: page.title,
                    url: cobrandLink(`/travel/${page.friendlyName}`)
                });
            });


            // Get random products
            getProductCards(ProductType.CRUISES, { rowsLimit: 3 }).then((productCards) => {
                if (productCards.productCards.length) {
                    productCardsCruises.value.productCards = productCards.productCards;
                    productCardsCruises.value.viewAllText = getViewAllLabel(ProductType.CRUISES, productCards.totalResults, 3);
                }
            });

            getProductCards(ProductType.HOTELS, { rowsLimit: 3 }).then((productCards) => {
                if (productCards.productCards.length) {
                    productCardsHotels.value.productCards = productCards.productCards;
                    productCardsHotels.value.viewAllText = getViewAllLabel(ProductType.HOTELS, productCards.totalResults, 3);
                }
            });

            getProductCards(ProductType.TOURS, { rowsLimit: 3 }).then((productCards) => {
                if (productCards.productCards.length) {
                    productCardsTours.value.productCards = productCards.productCards;
                    productCardsTours.value.viewAllText = getViewAllLabel(ProductType.TOURS, productCards.totalResults, 3);
                }
            });

            isReady.value = true;

        } else {
            console.error("No inspiration pages found", pagesJSON);
        }
    }).catch((err) => {

        // TODO -- display something useful if it 404s (which it shouldn't)
        toastError("Error retrieving data");
        console.error("Error retrieving inspiration pages", err);
    });

</script>

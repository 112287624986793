<template>
    <div v-if="isReady">
        <div class="container px-lg-0 pb-0 my-2">
            <h1 v-html="pageTitle"></h1>
        </div>

        <section class="mb-3">
            <featured-article-component v-if="featuredArticle" :content="featuredArticle" class="container px-0" :page-name="pageName"></featured-article-component>
            <div v-else class="container px-lg-0 mb-6">No articles found.</div>
        </section>

        <section class="container px-lg-0">
            <article-cards-component :event-start-index="2" header-text="" :page-name="pageName" :lazy-load="true" :search-criteria="articleSearchCriteria"></article-cards-component>
        </section>

        <section v-if="hasHtmlBlobs" :class="htmlBlobContent[0].containerClasses" data-testid="blob-section">
            <template v-for="(htmlBlob, blobIndex) in htmlBlobs" :key="blobIndex">
                <html-blob-component :html="htmlBlob.toString()"></html-blob-component>
            </template>
        </section>

        <find-an-advisor-cta-component></find-an-advisor-cta-component>

        <div class="ad-zone"><broadstreet-zone zone-id="78463"></broadstreet-zone></div>

        <section v-if="featuredDestinations.length" class="container-xl px-1 px-lg-0">
            <h2 class="text--serif mb-2" v-html="featuredDestinationsHeader"></h2>
            <feature-cards-component class="featured-destinations" :content="featuredDestinations" :use-destination-tracking="true"></feature-cards-component>
        </section>

        <product-cards-component :content="hotels" class="product-cards-container"></product-cards-component>
        <product-cards-component :content="cruises" :class="['product-cards-container', { 'pt-0': hotels.productCards.length }]"></product-cards-component>
        <product-cards-component :content="tours" :class="['product-cards-container', { 'pt-0': (hotels.productCards.length || cruises.productCards.length ) }]"></product-cards-component>
    </div>
    <AppError v-else-if="hasError" />
    <LogoSplash v-else />
</template>

<script setup lang="ts">
    import { addToShownArticleIdList, getArticles } from "api/articles";
    import { getCmsContent, getCmsPage } from "api/cms";
    import { getDestinationInfo } from "api/destinations";
    import { ArticleCard, FeatureCard, ProductCards } from "interfaces/card";
    import { CmsPageContainer, CmsSearchCriteria, ProductQuery } from "interfaces/cms";
    import { DestinationDetails, DestinationSelectedFacets } from "interfaces/destination";
    import { ProductType } from "interfaces/enums";
    import { DotCMSFeaturedDestinationsResponse } from "interfaces/responses/destination-responses";
    import { DotCMSBasicArticleResponse, DotCMSDestinationsResponse } from "interfaces/responses/dotcms-responses";
    import { getDestinationQueryClauseValue, transformDotCMSPageContainers } from "modules/cms";
    import { getProductCards, getViewAllLabel } from "modules/product-cards";
    import { toastError } from "modules/toasts";
    import { transformCmsBasicArticleToArticleCard } from "services/article";
    import { getCategoryVar } from "services/categories";
    import { buildDestinationPageURL, buildFacetedProductSearchUrl } from "services/destinations";
    import { generateCmsImageUrl } from "services/images";
    import { cobrandLink } from "virtuoso-shared-web-ui";
    import { ref } from "vue";
    import AppError from "vue-components/app-error.vue";
    import ArticleCardsComponent from "vue-components/article-cards.vue";
    import FeatureCardsComponent from "vue-components/feature-cards.vue";
    import FeaturedArticleComponent from "vue-components/featured-article.vue";
    import FindAnAdvisorCtaComponent from "vue-components/find-an-advisor-cta.vue";
    import HtmlBlobComponent from "vue-components/html-blob-allow-side-effects.vue";
    import LogoSplash from "vue-components/logo-splash.vue";
    import ProductCardsComponent from "vue-components/product-cards.vue";

    const props = defineProps({
        topicKey: {
            type: String,
            required: true
        },
        destinationKey: {
            type: String,
            required: false,
            default: ""
        }
    });

    const category = getCategoryVar(props.topicKey);
    // Virtuoso, The Magazine category tag was renamed from Virtuoso Life but the dotCMS variable cannot be changed
    // Use the appropriate dotCMS variable reference here so it pulls the correct articles
    const pageCategory = (category === "virtuosoTheMagazine") ? "virtuosoLife" : category;

    const pageTitle = ref("");
    const featuredDestinations = ref<FeatureCard[]>([]);
    const featuredDestinationsHeader = ref("");

    const cruises = ref<ProductCards>({
        header: "Cruises",
        productCards: [],
        productType: ProductType.CRUISES,
        viewAllLink: cobrandLink("/travel/luxury-cruises/search/#"),
        viewAllText: "See More Cruises"
    });

    const hotels = ref<ProductCards>({
        header: "Hotels",
        productCards: [],
        productType: ProductType.HOTELS,
        viewAllLink: cobrandLink("/travel/luxury-hotels/search/#"),
        viewAllText: "See More Hotels"
    });

    const tours = ref<ProductCards>({
        header: "Tours & Experiences",
        productCards: [],
        productType: ProductType.TOURS,
        viewAllLink: cobrandLink("/travel/luxury-tours/search/#"),
        viewAllText: "See More Tours & Experiences"
    });

    const pageName = ref(`Inspiration: ${pageTitle.value}`);
    const cruisesQuery = ref<ProductQuery>({ rowsLimit: 3, selectedFacets: [] });
    const hotelsQuery = ref<ProductQuery>({ rowsLimit: 3, selectedFacets: [] });
    const toursQuery = ref<ProductQuery>({ rowsLimit: 3, selectedFacets: [] });
    const hasCruises = ref(false); // This is true if the destinations call returns with with selected facets for the product
    const hasHotels = ref(false);
    const hasTours = ref(false);
    const shouldShowCruises = ref(false); // This is true only for a subset of category/product combinations, otherwise we don't show some/all product types
    const shouldShowHotels = ref(false); // Currently we're not showing any cruises because there aren't appropriate catalog filters
    const shouldShowTours = ref(false); // Accordingly, a topic front may show no products, only tours, only hotels, or only hotels and tours
    const isReady = ref(false);
    const hasError = ref(false);

    const htmlBlobContent = ref<CmsPageContainer<CmsPageContainer<string>[]>[]>([]);
    const htmlBlobs = ref<string[]>([]);
    const hasHtmlBlobs = ref(false);

    const featuredArticleId = ref("");
    const featuredArticle = ref<ArticleCard>(undefined);
    const articleSearchCriteria = ref<CmsSearchCriteria>({});

    getCmsPage(`/inspiration/${props.topicKey}`).then((cmsPageResponse) => {

        const cmsPage = transformDotCMSPageContainers(cmsPageResponse);

        pageTitle.value = cmsPage.title;

        let destinationDetailsPromise;
        let destinationDetails = ref<DestinationDetails>(undefined);
        if (props.destinationKey) {

            destinationDetailsPromise = getDestinationInfo(`/${props.destinationKey.replace(/\|/g, "/")}`);
            destinationDetailsPromise.then((destDetails: DestinationDetails) => {

                if (destDetails.name) {
                    pageTitle.value += ` in ${destDetails.name}`;

                    // Filtered to destinations, ignore the featured article
                    cmsPage.containers = (cmsPage.containers).filter((container) => container.friendlyName !== "featured-article");
                    destinationDetails.value = destDetails;
                } else {
                    // Invalid destination, redirect to the non-filtered topic front instead
                    console.error("Invalid destination name: ", destDetails?.name);
                    location.href = cobrandLink(`/travel/${window.VIRTUOSO.topicKey}`);
                }

            }, () => {
                // Invalid destination, redirect to the non-filtered topic front instead
                console.error("Invalid destination, no data returned for: ", window.VIRTUOSO.destinationKey.replace(/|/g, "/"));
                location.href = cobrandLink(`/travel/${window.VIRTUOSO.topicKey}`);
            });
        }

        htmlBlobContent.value = (cmsPage.containers).filter((container) => container.friendlyName === "html-blob") as CmsPageContainer<CmsPageContainer<string>[]>[];
        if (htmlBlobContent.value.length) {
            (htmlBlobContent.value).forEach((htmlBlob) => {
                if (htmlBlob.content && htmlBlob.content.length) {
                    (htmlBlob.content).forEach((htmlString) => {
                        if (htmlString.content) {
                            htmlBlobs.value.push(htmlString.content);
                            hasHtmlBlobs.value = true;
                        }
                    });
                }
            });
        }


        let articlePullPromise; // If we need to look up the latest article, we need to wait for it to resolve before we pull the other articles
        const destinationCmsQueryClause = (props.destinationKey) ? `+ConsumerArticleBasic.tags:(${getDestinationQueryClauseValue(props.destinationKey)})` : ""; // Add destination filter if needed

        // Featured Article
        const featuredArticleContainer: CmsPageContainer<DotCMSBasicArticleResponse[]>[] = (cmsPage.containers).filter((container) => container.friendlyName === "featured-article") as CmsPageContainer<DotCMSBasicArticleResponse[]>[];

        if (cmsPage.containers.length && featuredArticleContainer.length && featuredArticleContainer[0].content.length) {
            // There is a selected article, pass it through
            const articleCmsContent = featuredArticleContainer[0].content[0];

            // Transform into a simple shape
            featuredArticle.value = transformCmsBasicArticleToArticleCard(articleCmsContent, {});
            featuredArticleId.value = articleCmsContent.identifier;
        } else {

            // None selected, query and get a random, recent, non-sponsored article
            if (pageCategory) {
                articlePullPromise = getArticles({
                    contentTypes: ["ConsumerArticleBasic"],
                    limit: 5,
                    queryClauses: [
                        `+(categories:${pageCategory})`,
                        "+(!ConsumerArticleBasic.isSponsoredContent:1)",
                        destinationCmsQueryClause
                    ],
                    sort: "ConsumerArticleBasic.publish desc"
                }, false);

                articlePullPromise.then((articleJSON) => {
                    if (articleJSON.articles.length) {

                        const randomNumber = Math.floor(Math.random() * articleJSON.articles.length) + 1;
                        const articleCmsContent = articleJSON.articles[randomNumber - 1];

                        featuredArticle.value = transformCmsBasicArticleToArticleCard(articleCmsContent, {});

                        featuredArticleId.value = articleCmsContent.identifier;
                        addToShownArticleIdList([featuredArticleId.value]);
                    }
                }).catch(() => {
                    toastError("Error retrieving articles");
                });
            } else {
                console.error("Invalid category");
            }
        }


        // Wait for automatic latest story pull, if necessary, to happen so we can exclude it from the "more stories" query
        Promise.all([destinationDetailsPromise, articlePullPromise]).then(() => {
            // More Stories (with paging)
            articleSearchCriteria.value = {
                contentTypes: ["ConsumerArticleBasic"],
                queryClauses: [
                    `+(categories:${pageCategory})`,
                    `-(identifier:${featuredArticleId.value})`,
                    destinationCmsQueryClause
                ],
                sort: "ConsumerArticleBasic.publish desc"
            };

            // Featured Destinations (optional)
            const featuredDestinationContainers: CmsPageContainer<DotCMSDestinationsResponse[]>[] = (cmsPage.containers).filter((container) => container.friendlyName === "featured-destinations") as CmsPageContainer<DotCMSDestinationsResponse[]>[];

            if (featuredDestinationContainers.length) { // There shouldn't be more than one of these, but what the heck
                featuredDestinationContainers.forEach((container) => {
                    if (container.content.length && container.content[0].identifier) {

                        featuredDestinationsHeader.value = (container.content && container.content[0] && container.content[0].title) ? container.content[0].title : `${cmsPage.title} Destinations We&nbsp;Love`;

                        getCmsContent<DotCMSFeaturedDestinationsResponse[]>({
                            contentTypes: ["FeaturedDestinations"],
                            depth: 1,
                            limit: 1,
                            queryClauses: [
                                `+(identifier:${container.content[0].identifier})`
                            ]
                        }).then((dests) => {
                            if (dests?.length && dests[0].destinations && dests[0].destinations.length) {

                                // Transform into a simple shape
                                // TODO: Only populate if there are exactly 5???
                                (dests[0].destinations).forEach((featuredDest) => {
                                    featuredDestinations.value.push({
                                        imageUrl: generateCmsImageUrl(featuredDest.gridImage),
                                        url: buildDestinationPageURL(featuredDest.destinationTag, featuredDest.isArbitraryDestination),
                                        name: featuredDest.title
                                    });
                                });
                            }
                        }, () => {
                            // It's okay if there's none, we just don't display anything
                        });

                    }
                });
            }

            // Add additional filters for specific categories
            // Currently not using any Communities filters
            let cruiseFacets: DestinationSelectedFacets[] = [];
            let tourFacets: DestinationSelectedFacets[] = [];
            let hotelFacets: DestinationSelectedFacets[] = [];
            switch (pageCategory) {
                case "adventure":
                    hotelFacets.push({ category: "property_experiences", name: "Adventure" });
                    tourFacets.push({ category: "tour_experiences", name: "Adventure" });
                    shouldShowHotels.value = shouldShowTours.value = true;
                    break;
                case "artsCulture":
                    tourFacets.push({ category: "rout_experiences", name: "Culture, History & Arts" });
                    shouldShowTours.value = true;
                    break;
                case "family":
                    tourFacets.push({ category: "tour_experiences", name: "Family Vacations" });
                    shouldShowTours.value = true;
                    break;
                case "foodWine":
                    tourFacets.push({ category: "tour_experiences", name: "Food & Win" });
                    shouldShowTours.value = true;
                    break;
                case "sustainableTravel":
                    hotelFacets.push({ category: "property_experiences", name: "Ecotourism" });
                    shouldShowHotels.value = true;
                    break;
                case "ultraluxe":
                    hotelFacets.push({ category: "product_community_list", name: "Virtuoso Ultraluxe Travel" });
                    cruiseFacets.push({ category: "product_community_list", name: "Virtuoso Ultraluxe Travel" });
                    tourFacets.push({ category: "product_community_list", name: "Virtuoso Ultraluxe Travel" });
                    shouldShowHotels.value = shouldShowTours.value = shouldShowCruises.value = true;
                    break;
                case "wellness":
                    hotelFacets.push({ category: "property_experiences", name: "Wellness" });
                    tourFacets.push({ category: "tour_experiences", name: "Wellness" });
                    shouldShowHotels.value = shouldShowTours.value = true;
                    break;
            }


            if (destinationDetails.value) { // Only filter by destination if, well, we're filtered by destination
                hasHotels.value = destinationDetails.value.hotelTile?.count > 0;
                hasCruises.value = destinationDetails.value.cruiseTile?.count > 0;
                hasTours.value = destinationDetails.value.tourTile?.count > 0;

                hotelsQuery.value.selectedFacets.push(...destinationDetails.value.hotelTile?.selectedFacets.map((facet) => ({ category: facet.category, name: facet.name, selected: true })) ?? []);
                cruisesQuery.value.selectedFacets.push(...destinationDetails.value.cruiseTile?.selectedFacets.map((facet) => ({ category: facet.category, name: facet.name, selected: true })) ?? []);
                toursQuery.value.selectedFacets.push(...destinationDetails.value.tourTile?.selectedFacets.map((facet) => ({ category: facet.category, name: facet.name, selected: true })) ?? []);

                hotelFacets.push(...(destinationDetails.value.hotelTile?.selectedFacets ?? []));
                cruiseFacets.push(...(destinationDetails.value.cruiseTile?.selectedFacets ?? []));
                tourFacets.push(...(destinationDetails.value.tourTile?.selectedFacets ?? []));
            }

            const hotelSearchLink = buildFacetedProductSearchUrl(ProductType.HOTELS, hotelFacets);
            const cruiseSearchLink = buildFacetedProductSearchUrl(ProductType.CRUISES, cruiseFacets);
            const tourSearchLink = buildFacetedProductSearchUrl(ProductType.TOURS, tourFacets);

            if (shouldShowCruises.value && (hasCruises.value || !destinationDetails.value)) {
                getProductCards(ProductType.CRUISES, cruisesQuery.value).then((cards) => {
                    if (cards.productCards.length) {
                        cruises.value.productCards = cards.productCards;
                        cruises.value.viewAllText = getViewAllLabel(ProductType.CRUISES, cards.totalResults, cruisesQuery.value.rowsLimit);
                        cruises.value.viewAllLink = (cards.totalResults <= cruisesQuery.value.rowsLimit) ? cobrandLink("/travel/luxury-cruises/search") : cruiseSearchLink;
                    }
                });
            }

            if (shouldShowHotels.value && (hasHotels.value || !destinationDetails.value)) {
                getProductCards(ProductType.HOTELS, hotelsQuery.value).then((cards) => {
                    if (cards.productCards.length) {
                        hotels.value.productCards = cards.productCards;
                        hotels.value.viewAllText = getViewAllLabel(ProductType.HOTELS, cards.totalResults, hotelsQuery.value.rowsLimit);
                        hotels.value.viewAllLink = (cards.totalResults <= hotelsQuery.value.rowsLimit) ? cobrandLink("/travel/luxury-hotels/search") : hotelSearchLink;
                    }
                });
            }

            if (shouldShowTours.value && (hasTours.value || !destinationDetails.value)) {
                getProductCards(ProductType.TOURS, toursQuery.value).then((cards) => {
                    if (cards.productCards.length) {
                        tours.value.productCards = cards.productCards;
                        tours.value.viewAllText = getViewAllLabel(ProductType.TOURS, cards.totalResults, toursQuery.value.rowsLimit);
                        tours.value.viewAllLink = (cards.totalResults <= toursQuery.value.rowsLimit) ? cobrandLink("/travel/luxury-tours/search") : tourSearchLink;
                    }
                });
            }

            isReady.value = true;
        });
    }, (e) => {
        toastError("Error retrieving data");
        console.error("Error retrieving data for topicKey: ", props.topicKey, e);
        hasError.value = true;
    });
</script>
